@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Roboto', sans-serif;
  color: #2b426a;
}

@media (min-width: 768px) {
  .custom-login-bg {
    background-image: url('./assets/Group\ 11.svg') !important;
    background-size: 100% 100%;
  }
  .scroll-menu {
    max-height: 500px;
    overflow-y: scroll;
  }
}
.custom-login-bg {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-image: url('./assets/icon.svg');
  background-position-y: bottom;
  background-position-x: center;
}

.custom-input {
  background: #d5f0ff;
  border: 1px solid #2b426a;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
}

.custom-btn {
  background: #2b426a;
  border: 1px solid #2b426a;
  border-radius: 5px;
  width: 100%;
}

input::placeholder {
  color: #2b426a;
  text-transform: uppercase;
  text-align: center;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2b426a;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2b426a;
}

.procedure-wrapper {
  border: 1px solid #2b426a;
  border-radius: 0px 5px 5px 0px;
}

.procedure-number {
  background: #d5f0ff;
}

::-webkit-scrollbar {
  width: 26px;
}
::-webkit-scrollbar-track {
  background: #2b426a;
  border-left: 12px solid white;
  border-right: 12px solid white;
}
::-webkit-scrollbar-thumb {
  background-repeat: no-repeat;
  background-image: url('./assets/Group\ 10.svg');
  background-position: center;
}

.secondary-btn {
  background: #2b426a;
  border-radius: 5px;
}

.preview-box {
  background: #ffffff;
  border: 1px solid #2b426a;
  border-radius: 5px;
}

.message-wrapper {
  border-top: 1px solid #2b426a;
}

.custom-border {
  border: 1px solid #2b426a;
}

.confirm-btn {
  background: #d5f0ff;
}
